@use "../../config/" as *;
.static-content,
.waf-detail {
	.title {
		@extend %py-3;
		@extend %font-18-pb;
		@extend %neutral-50;
	}
	p,
	.text,
	li {
		line-height: 3rem;
		@extend %font-14-pr;
		@extend %neutral-900-8;
	}
	p {
		@extend %mb-3;
	}
	.meta {
		@extend %mb-2;
		@extend %font-10-pr;
		@extend %secondary-800;
	}
	a {
		text-decoration: underline;
		@extend %neutral-800;
		@extend %font-14-pb;
	}
	strong {
		@extend %font-14-pb;
	}
	.table-responsive table {
		display: table;
	}
	.sub-title,
	.highlight,
	.detail-sub-title {
		line-height: 2.6rem;
		@extend %font-14-pb;
		@extend %secondary-1000;
		@extend %mb-2;
	}
	// .blockquote {
	// 	border-bottom: .1rem solid hsl(var(--hsl-neutral-1000) / .1);
	// 	border-top: .1rem solid hsl(var(--hsl-neutral-1000) / .1);
	// }
	ul,
	ol {
		@extend %pl-3;
		@extend %mb-3;
		li {
			@extend %mb-3;
			&::marker {
				@extend %font-14-pb;
				@extend %secondary-1000;
			}
		}
	}
	thead {
		tr {
			th {
				@extend %font-14-pb;
				@extend %neutral-50;
			}
		}
	}
	tbody {
		td {
			@extend %neutral-800;
			@extend %font-14-pr;
		}
	}
	table,
	th,
	td {
		border: 0.1rem solid hsl(var(--hsl-neutral-1000) / .1);
		border-collapse: collapse;
	}
	.number-list > li {
		list-style-type: decimal;
		@extend %font-14-pb;
		@extend %secondary-1000;
	}
	.alpha-type > li {
		list-style-type: lower-alpha;
	}
	.roman-list > li {
		list-style-type: lower-roman;
	}
	.no-list-style > li {
		list-style-type: none;
	}
	.lower-roman {
		list-style-type: lower-roman;
	}
	.upper-roman {
		list-style-type: upper-roman;
	}
	.disc-list {
		list-style-type: disc;
	}
	.circle-list {
		list-style-type: circle;
	}
	.lower-alpha {
		list-style-type: lower-alpha;
	}
	.upper-alpha {
		list-style-type: upper-alpha;
	}
	video {
		width: 100%;
	}
	img,
	video {
		@extend %half-radius;
	}
	table {
		@extend %my-2;
	}
	.article-body {
		@extend %p-3;
		border-radius: var(--full-radius);
		@extend %neutral-50-bg;
	}
}
@media screen and (min-width: $tablet-min-width) {
	.static-content,
	.waf-detail {
		.waf {
			&-body {
				padding: 4rem 1rem;
			}
			&-head {
				.title {
					font-size: 2.8rem;
				}
			}
		}
		.title {
			font-size: 2.6rem;
		}
		.article-body {
			padding: var(--space-6);
		}
		.sub-title,
		.highlight {
			font-size: 1.8rem;
		}
		p {
			font-size: 1.4rem;
		}
		strong {
			font-size: 1.6rem;
		}
		ol,
		ul {
			li {
				font-size: 1.4rem;
			}
		}
		.caption {
			font-size: 1.3rem;
		}
		.number-list > li {
			font-size: 1.8rem;
			&::marker {
				font-size: 1.8rem;
			}
		}
	}
}